.card {
  height: 150px;
  width: 20em;
  text-align: center;
  border-radius: 10px;
  padding: 50px;
  margin: 20px;
  transition: 0.7s ease;
  text-decoration: none;
}
.card:hover {
  transform: scale(1.1);
}

