.c1a{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color: yellow;
}
.c1b{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color: rgb(6, 160, 60);
}
.c1c{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color: rgb(6, 160, 60);
}
.c1d{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color: rgb(6, 160, 60);
}
.c1e{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color:green;
}
.c2a{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color:yellow;
}
.c2b{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color: yellow;
}
.c2c{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color:yellow;
}
.c2d{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color: green;
}
.c2e{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color: green;
}
.c3a{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color: red;
}
.c3b{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color:yellow;
}
.c3c{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color:yellow;
}
.c3d{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color: yellow;
}
.c3e{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color: green;
}
.c4a{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color: red;
}
.c4b{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color:red;
}
.c4c{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color:yellow;
}
.c4d{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color: yellow;
}
.c4e{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color: yellow;
}
.c5a{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color:red;
}
.c5b{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color: red;
}
.c5c{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color:red;
}
.c5d{
width: 150px;
height: 50px;
border-style: double;
border-width: 3px;
background-color:yellow;
}
.c5e{
  width: 150px;
  height: 50px;
  border-style: double;
  border-width: 3px;
  background-color: yellow;
  }
.cc{
  width: 150px;
  height: 100px;
  border-style: solid;
  border-width: 1px;
  background-color: rgb(255, 255, 255);
  }
.ccw{
    width: 750px;
    height: 60px;
    border-style: solid;
    border-width: 1px;
    background-color: rgb(255, 255, 255);
    }
        
.matrixrow
{
  display:flex;
  justify-content: center;
}
.srd
{
  width: 300px;
  text-align: left;
  border-style:solid;
border-width: 1px;
}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          