table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 90%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.col1
{
  width: 80px;
}

.col2
{
  width: 80px;
}

.col3
{
  width: 150px;
}

.col4
{
  width: auto;
}

.col5
{
  width: auto;
}

.col6
{
  width: 80px;
}

