

.Login {
  text-align: center;
  width:300px;
  margin: auto ;
  align-items: center;
  border-radius: 8px;
  position: absolute;
  top: 18%;
  left: 40%;
}
