.side-bar {
  padding: 20px;
  min-width: 200px;
  max-width: 200px;
  height: 87vh;

  z-index: 1;
  overflow-x: hidden;
  color: black;
  border-right: 3px solid gray;
}
.side-link {
  color: black;
  text-decoration: none;
  font-size: 25px;
}
.side-link:hover {
  color: #f1f1f1;
}
