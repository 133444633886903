.navbar {
  height: 80px;
  color: rgb(10, 10, 10);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 2px 2px 15px 2px gray;
  font-size: 25px;
}
.info {
  font-size: 1.2em;
  font-weight: 700;
  margin: 10px;
  text-decoration: none;
  display: flex;
  align-items: center;
  list-style: none;
}
.nav-text {
  text-decoration: none;
  padding: 20px;
  font-size: 1.2em;
  font-weight: 700;
  color: black;
}
.header-text {
  font-size: 1em;
  font-weight: 700;
  color: black;
}

.nav-text:hover {
  color: #f1f1f1;
}
